import axios from 'axios';
import appHelper from './appHelper';
import jwtHelper from "./jwtHelper";
import msgHelper from './msgHelper';


const httpHelper = {
    init,
    axiosInst,
    logResponse,
    requestResult,
    showErrMsg,
    downloadFile
}

export default httpHelper;

class HttpHelper{
    init(isLoadingHandler, history){
        this.isLoadingHandler = isLoadingHandler;
        this.history = history;
        this.initAxios();
    }

    initAxios(){
        this.axios = axios.create();
        
        this.axios.interceptors.request.use( config => {
            config.headers.Authorization = jwtHelper.getJWT();
            this.isLoadingHandler(true);
            return config;
        });      
        this.axios.interceptors.response.use( response => {
                logResponse(response);   
                this.isLoadingHandler(false);

                return response;
            }, 
            error => {
                logResponse(error.response);
                this.isLoadingHandler(false);
                showErrMsg(error.response);  
                
                if(error.response.status === 403){
                    //AuthService.logout();
                    //this.history.push('/');
                }

                return Promise.reject(error);
          }
        );
    }

    getAxios(){
        return this.axios;
    }
}

const helper = new HttpHelper();

function init(isLoadingHandler, history){
    helper.init(isLoadingHandler, history);
}

/**
 * Axios instanse with request header for 'Authorization: Bearer <JWT>'
 */
function axiosInst(){
    return helper.getAxios();
}

/**
 * Log http response data
 */
function logResponse(res){
    appHelper.log('response: \n' + 
            'status: ' + res.status + '\n' + 
            'statusText: ' + res.statusText + '\n' +  
            'data: ' + JSON.stringify(res.data).slice(0,200));
}


/**
 * return request result status
 */
function requestResult(res){
    return {
        'success': res.status === 200 ? true: false,
        'status': res.status,
        'msg': res.statusText,
        'data': res.data
    };
}

/**
 * Показать сообщение об ошибке
 * 
 * @param {string} status http response status
 * @param {function} callback вызов функции {App.showMessage} для отображения сообщения
 */
function showErrMsg(res){
    let msg = 'Ошибка авторизации';
    switch (res.status) {
        case 401:
            msgHelper.show(false, 'error', msg, 'Логин/пароль не найден');
            break;
        case 403:
            msgHelper.show(false, 'error', msg, 'Доступ запрещен, необходимо выполнить вход в систему');
            break;
        case 404:
            msgHelper.show(false, 'error', 'Ресурс не найден', res.config.url);
            break;
        default:
            //msgHelper.show(true, 'error', res.data);                   
            new Blob([res.data]).text().then(txt => msgHelper.show(true, 'error', txt));        
    }    
}

/**
 * Вызов функции загрузки файла браузером, файл собирается из переданного массива байт и названия файла
 * 
 * @param {byte[]} data 
 * @param {String} fileName 
 */
function downloadFile(data, fileName){
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}