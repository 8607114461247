import { Button } from 'primereact/button';
import React from 'react';
import profileService from './service/ProfileService';
import envHelper from './helpers/envHelper';

export const AppProfile = (props) => {
    const user = profileService.getUser();
    let botUrl = `https://t.me/FMAssistant${envHelper.getVar('FMABOT_SUFFIX')}?start=${user.id}`;

    return (
        <div className="layout-profile">
            <div className="layout-profile-item p-text-center p-text-bold">
                <h2>{user.managedObject}</h2>
            </div>  

            <div className="layout-profile-item">
                <span className="username">{user.person}</span>
            </div>

            <div className="layout-profile-item">
                <Button type="button" className="telegram p-p-0" onClick={() => window.open(botUrl, '_blank', 'noopener,noreferrer')}>
                    <i className="pi pi-telegram p-px-1"/>
                    <span className="p-px-1">Подключить</span>                      
                </Button> 
            </div>
        </div>
    )
}