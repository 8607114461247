import React, { useEffect, useState } from 'react';

import { Chart } from 'primereact/chart';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button'
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';

import profileService from '../../service/ProfileService'; 

const Dashboard = (props) => {

    const [paymentLineData, setPaymentLineData] = useState();
    const [workorderLineData, setWorkorderLineData] = useState();
    
    useEffect( () => {
        profileService.getClientStats().then( stats => {
            var paymentStats = extractPaymentStats(stats.data.paymentStats);
            //var workOrderStats = extractWorkorderStatsByMonth(stats.data.workOrderStats);
            var workOrderStats = extractWorkorderStatsByType(stats.data.workOrderStats);
        
            setPaymentLineData(paymentStats);
            setWorkorderLineData(workOrderStats);
        })
    }, []);

    function extractPaymentStats(stats) {
        return {
            labels: stats.monthsNameList,
            datasets: [
                {
                    label: 'Оплачено',
                    data: stats.payedSumList,
                    backgroundColor: '#20d077'
                },
                {
                    label: 'Не оплачено',
                    data: stats.unPayedSumList,
                    backgroundColor: '#007be5'
                }
            ],
            payed: stats.payedAmount,
            notPayed: stats.unPayedAmount
        };
    }

    function extractWorkorderStatsByMonth(stats) {
        return {
            labels: stats.monthsNameList,
            datasets: [
                {
                    label: 'Закрыто',
                    data: stats.closedList,
                    backgroundColor: '#20d077'
                },
                {
                    label: 'В работе',
                    data: stats.workList,
                    backgroundColor: '#007be5'
                }
            ],
            closedAmount: stats.closedAmount,
            workAmount: stats.workAmount,
        };
    }

    function extractWorkorderStatsByType(stats) {
        return {
            labels: ['Новая заявка', 'На рассмотрении', 'На исполнении', 'В режиме ожидания'],
            datasets: [
                {
                    data: [stats.newAmount, stats.dispathAmount, stats.assignedAmount, stats.waitAmount],
                    backgroundColor: ['#007be5', '#f9c851', '#20d077', 'hotpink']
                }
            ],
            workAmount: stats.workAmount,
            closedAmount: stats.closedAmount,
        };
    }

    const workordersTitle = () => {
        return  <div className="p-fluid p-grid">            
                    <div className="p-col-12 p-md-10">
                        <span className="pi pi-fw pi-briefcase p-mr-1"/><span>Заявки</span>
                    </div>
                    <div className="p-col-12 p-md-2">
                        <Link to="new-workorder">
                            <Button type="button" label="Создать" icon="pi pi-plus" />                
                        </Link>
                    </div>
                </div>
    }

    return (
        <>
        <Card title={<><span className="pi pi-fw pi-book p-mr-1"/><span>Платежи</span></>}>
            <div className="p-grid p-fluid dashboard">            
                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">К оплате</span>
                        {paymentLineData &&
                            <Link to="payments?state=notpayed"><span className="count payment-not-payed">{paymentLineData.notPayed}</span></Link>
                        }
                        {paymentLineData &&
                            paymentLineData.notPayed === 0 
                                ?   <div className="p-mt-4"><Message severity="info" text="У вас нет неоплаченных документов"/></div>
                                :   <></>
                        }
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">Оплачено</span>
                        {paymentLineData &&
                            <Link to="payments?state=payed"><span className="count payment-payed">{paymentLineData.payed}</span></Link>
                        }
                        {paymentLineData &&
                            paymentLineData.payed === 0 
                                ?   <div className="p-mt-4"><Message severity="info" text="Вы еще не оплачивали документы"/></div>
                                :   <></>
                        }
                    </div>
                </div>   

                <div className="p-col-12 p-lg-6">
                    <div className="card">
                        <div className="p-text-center p-text-bold">Статистика платежей (за год)</div>
                        {paymentLineData 
                            ? <Chart type="bar" data={paymentLineData} />
                            : <Skeleton height="15em" />
                        }
                    </div>
                </div>
            </div>
        </Card>
        <br/>
        <Card title={workordersTitle()}>
            <div className="p-grid p-fluid dashboard">            
                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">В работе</span>
                        {workorderLineData &&
                            <Link to="workorders?state=working"><span className="count workorder-working">{workorderLineData.workAmount}</span></Link>                                        
                        }
                        {workorderLineData ?
                            workorderLineData.workAmount > 0 
                                ?   <div className="detail p-mt-3">
                                        <Chart type="doughnut" data={workorderLineData}/>
                                    </div>                        
                                :   <div className="p-mt-4"><Message severity="info" text="У вас нет заявок в работе"/></div>
                            : <Skeleton height="15rem" />
                        }
                    </div>
                </div>
                
                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">Закрыто</span>
                        {workorderLineData && 
                            <Link to="workorders?state=closed"><span className="count workorder-closed">{workorderLineData.closedAmount}</span></Link>
                        }
                        {workorderLineData &&
                            workorderLineData.closedAmount === 0 
                            ?   <div className="p-mt-4"><Message severity="info" text="У вас нет закрытых заявок"/></div>
                            :   <></>
                        }
                    </div>
                </div>   
            </div>
        </Card>
        </>
);
}

export default Dashboard;
