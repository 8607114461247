const jwtHelper = {
    setJWT,
    getJWT,
    clearJWT
}

export default jwtHelper;

function setJWT(jwt){
    sessionStorage.setItem("jwt", jwt);
}

function getJWT(){
    return sessionStorage.getItem("jwt");
}

function clearJWT() {
    sessionStorage.removeItem('jwt');
}