import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import {Toolbar} from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';

import { Skeleton } from 'primereact/skeleton';

import paymentService from './paymentService';
import appHelper from '../../../helpers/appHelper';

import './payments.scss';

const PaymentInfo = (props) => {
    const { docId }= useParams();
    const [doc, setDoc] = useState();
    const [tx, setTx] = useState();
    const [messages, setMessages] = useState();

    const history = useHistory();

    useEffect( () => {
        if(messages){
            if(!doc){
                paymentService.getPayment(docId).then(doc => {
                    setDoc(doc);
                    setTx(doc.tx);
                    
                    if(doc.messages.length !== 0){
                        doc.messages.forEach(msg => {
                            messages.show({...msg, sticky: true});
                        }); 
                    }
                });
            }
        }
    }, [docId, doc, messages]);


    function isPaid(){
        return doc && doc.state.code === "PAID_COMPLETELY";
    }

    function onGetReport(){
        paymentService.getPaymentReport(doc.id, doc.text);
    }

    function onPay(){
        if(doc && isPaid()){
            messages.show({severity: 'info', summary: 'Документ уже оплачен'});
            return;
        }
        if(!tx){
            messages.show({severity: 'warn', summary: 'Необходимо рассчитать сумму к оплате сначала'});
            return;
        }

        paymentService.pay(docId, {params: {'apiCallbackUrl': window.location.origin + `/#/payment-success/${docId}`}}).then(doc => {
            setDoc(doc);

            let hasErrors = false;
            doc.messages.forEach(msg => {
                messages.show({...msg, sticky: false});
                if(msg.severity === "error"){
                    hasErrors = true;
                }
            }); 
            
            if(!isPaid() && !hasErrors && doc.tx.psPayFormUrl){
                window.location.href = doc.tx.psPayFormUrl;
            }
        })
    }

    function renderHeaderToolbar(){
        return <>
            <Button icon="pi pi-print" label='PDF' className='p-mr-2' onClick={() => onGetReport()} disabled={!doc}/>
            <Button icon="pi pi-credit-card" label='Оплатить' onClick={() => onPay()}  disabled={isPaid() || !(doc && tx)}/>
        </>
    }

    function renderDocHeader(){
        var stateClass;
        switch (doc && doc.state.code) {
            case 'UNPAID':
                stateClass =  'unpayed';
                break;
            case 'PAID_COMPLETELY':
                stateClass =  'payed';
                break;
            case 'PAID_PARTIALLY':
                stateClass =  'payed-part';
                break;
            case 'ERROR':
                stateClass =  'error';
                break;                
            default:
                stateClass =  'unpayed';;
        }
    
        return (
            <div className="p-grid payment-item-header">
                <div className="p-col-12 p-lg-10">
                    <div className="summary">
                        <span className="title">{doc && doc.text}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-2 p-text-nowrap">
                    <div className="summary">
                        <span className={'state ' + stateClass}>{doc && doc.state.description}</span>
                    </div>
                </div>
                <div className="p-col-12">
                        <Toolbar left={renderHeaderToolbar()}/>
                    </div>
            </div>
        )
    }

    function renderDoc(){
        return <Card title={renderDocHeader()}>
                <div className="p-fluid">
                    <Fieldset legend='Документ'>
                        <div className="p-field p-grid">
                            <label htmlFor="personAccountNumber" className="p-col-12 p-md-2">Лицевой счет</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputText id="personAccountNumber" value={doc.personAccountNumber} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="docNumber" className="p-col-12 p-md-2">Номер</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputText id="docNumber" value={doc.number} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="docDate" className="p-col-12 p-md-2">Дата</label>
                            <div className="p-col-12 p-md-10">
                                {doc 
                                ? 
                                    <div className="p-inputgroup">
                                        <InputText id="docDate" value={doc.docDate} readOnly/> 
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                    </div>
                                : 
                                    <Skeleton height="2.5rem" />
                                }
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="docSum" className="p-col-12 p-md-2">Сумма документа</label>
                            <div className="p-col-12 p-md-10">
                                {doc 
                                ? 
                                    <div className="p-inputgroup">
                                        <InputNumber id="docSum" value={doc.amount} readOnly/> 
                                        <span className="p-inputgroup-addon">руб.</span>
                                    </div>
                                    
                                : 
                                    <Skeleton height="2.5rem" />
                                }
                            </div>
                        </div>
                        {!isPaid() && <div className="p-field p-grid">
                                <label htmlFor="docSum" className="p-col-12 p-md-2">QR-код</label>
                                <div className="p-col-12 p-md-10">
                                    {doc  
                                    ? 
                                        <img id="qr-code" src={`data:image/jpeg;base64,${doc.imageQRCode}`}  alt='qr-код' />                                                                        
                                    : 
                                        <Skeleton height="2.5rem" />
                                    }
                                </div>
                        </div>                                                
                        }
                    </Fieldset>
                    <br/>
                    <Fieldset legend={!isPaid() ? 'К оплате' : 'Оплачено'}>
                        <div className="p-field p-grid">
                            <label htmlFor="comissionSum" className="p-col-12 p-md-2">Комиссия</label>
                            <div className="p-col-12 p-md-10">
                                {tx 
                                ? 
                                    <div className="p-inputgroup">
                                        <InputNumber id="comissionSum" value={tx.comissionSum} readOnly/> 
                                        <span className="p-inputgroup-addon">руб.</span>
                                    </div>
                                : 
                                    <Skeleton height="2.5rem" />
                                }
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="paymentSum" className="p-col-12 p-md-2">Сумма к оплате</label>
                            <div className="p-col-12 p-md-10">
                                {tx 
                                ? 
                                    <div className="p-inputgroup">
                                        <InputNumber id="paymentSum" value={tx.paymentSum}  readOnly/> 
                                        <span className="p-inputgroup-addon">руб.</span>
                                    </div>
                                : 
                                    <Skeleton height="2.5rem" />
                                }
                            </div>
                        </div>
                    </Fieldset>
                </div>                
            </Card>
    }

    return (    
        <>
            <Toast ref={ el => setMessages(el) }/>
            <div className="p-fluid p-d-flex p-jc-end p-mb-3">
                <Button type="button" label="К списку документов" icon="pi pi-chevron-left" className="p-button-secondary" onClick={() => history.push('/payments')}/>
            </div>
            {renderDoc()}
        </>)
}

export default PaymentInfo