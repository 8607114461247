import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import './workorders.scss';

import workorderService from './workorderService';
import msgHelper from '../../../helpers/msgHelper';
import { useHistory } from 'react-router-dom';
import FileView from '../files/FileView';

const NewWorkorder = (props) => {

    const [theme, setTheme] = useState('');
    const [text, setText] = useState('');
    const [categories, setCategories] = useState();
    const [category, setCategory] = useState();

    const [fileLimit, setFileLimit] = useState();
    const [fileSizeLimit, setfileSizeLimit] = useState();
    const [fileSizeLimitText, setfileSizeLimitText] = useState();

    const [lastFile, setLastFile] = useState();
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState({});

    const fileUpload = useRef();
    const [newWorkorder, setNewWorkorder] = useState();

    const history = useHistory();

    useEffect( () => {
        initNewWorkorder();
    }, []);

    function initNewWorkorder() {
        setText('');
        setTheme('');
        setCategory(null);
        setLastFile(null);
        setFiles([]);
        setErrors({});
        setNewWorkorder(null);

        workorderService
            .initNewWorkorder()
            .then(data => {
                setFileLimit(data[0]);
                setfileSizeLimit(data[1]);
                setfileSizeLimitText(data[2]);
                setCategories(data[3]);
            });
    }

    function handleSave(){
        let workorder = {};
        workorder.theme = theme;
        workorder.text = text;
        workorder.category = category;
        
        var hasErrors = false;
        if(theme === ''){
            errors['theme'] = true;
            hasErrors = true;
        }
        if(text === ''){
            errors['text'] = true;
            hasErrors = true;
        }
        setErrors(errors);

        if(!hasErrors){
            workorderService
                .newWorkorder(workorder)
                .then( res => {
                    if(res.success){
                        setNewWorkorder(res.data);
                    }
                    else{
                        msgHelper.show(false, "error", "Ошибка создания заявки",  res.msg);
                    }
                });
        }
    }
    
    function clearErrors(field){
        errors[field] = null;
    }

    const handleUpload = (event) => {
        const attach = event.files[0];

        let formData = new FormData();
        formData.append('attach', attach);
    
        workorderService
            .uploadNewFile(formData)
            .then( res => {
                if(res.success){
                    let file = res.data;
                    file.messages.forEach( msg => {
                        if(msg.severity === "error"){
                            msgHelper.show(false, "error", msg.summary, msg.detail);
                        }
                        else{
                            files.push(file);
                            setFiles(files);
                            setLastFile(file);
                            msgHelper.showSuccess('Файл загружен', file.name);        
                        }
                    })
                }
                else{
                    msgHelper.show(false, "error", "Ошибка загрузки файла",  res.msg);
                }
            });            
    
        fileUpload.current.clear();
    };
    
    const handleFilesClear = () => {
        initNewWorkorder();
        setFiles([]);
        setLastFile(null);
    }

    const renderNewForm = () => {
        return <Card title="Создание заявки">
                    <div className="p-fluid">
                        <div className="p-field p-grid">
                            <label htmlFor="theme" className="p-col-12 p-md-2">Тема</label>
                            <div className="p-col-12 p-md-10">
                                <InputText id="theme" type="text" placeholder="Напишите тему заявки" value={theme} onChange={(e) => {setTheme(e.target.value); clearErrors('theme');}} className={errors['theme'] ? 'p-invalid' : ''}/>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="category" className="p-col-12 p-md-2">Категория</label>
                            <div className="p-col-12 p-md-10 ">
                                <Dropdown panelClassName="new-workorder-category"
                                    id="category" 
                                    value={category} 
                                    onChange={(e) => setCategory(e.value)} 
                                    options={categories} 
                                    optionLabel="name" 
                                    optionValue="id" 
                                    placeholder="Выберите категорию" 
                                    filter showClear 
                                    emptyFilterMessage="Не найдено"></Dropdown>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="text" className="p-col-12 p-md-2">Содержимое заявки</label>
                            <div className="p-col-12 p-md-10">
                                <InputTextarea id="text" rows="4" placeholder="Опишите, что нужно сделать" value={text} onChange={(e) => {setText(e.target.value); clearErrors('text');}} className={errors['text'] ? 'p-invalid' : ''}/>
                            </div>
                        </div>
                        <div className="p-grid">
                            <label htmlFor="text" className="p-col-12 p-md-2">Прикрепить файлы</label>
                            <div className="p-col-12 p-md-10 p-d-flex p-ai-center">
                                <FileUpload 
                                    className="app-inline-fileupload"
                                    customUpload uploadHandler={handleUpload} 
                                    auto 
                                    maxFileSize={fileSizeLimit} 
                                    ref={fileUpload} 
                                    chooseOptions={{label: 'Выбрать', icon: 'pi pi-fw pi-plus'}} />
                                <span className="p-text-italic">(не более [{fileLimit}] файлов по [{fileSizeLimitText}])</span>
                            </div>
                        </div>
                        <br/>
                        <div className="p-field p-col-12">
                            <Fieldset legend='Прикрепленные файлы'>
                                {files.map( file => 
                                    <FileView file={file} key={file.id}/>
                                )}                        
                                {lastFile && 
                                    <div className="p-mt-4 p-d-inline-flex">
                                        <Button type="button" icon="pi pi-times" className='p-button-sm p-button-danger p-button-rounded p-button-outlined' onClick={handleFilesClear} label="Очистить"/>
                                    </div>
                                }                        
                            </Fieldset>
                        </div>
                        <div className="p-field">
                            <Button type="button" label="Создать" icon="pi pi-check" onClick={ () => handleSave()}/>
                        </div>
                    </div>
                </Card>
    }

    const renderCreatedForm = () => {
        return <Card title="Заявка создана">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        Вашей заявке [<span className="p-text-bold">{newWorkorder.theme}</span>] присвоен номер №<span className="p-text-bold">{newWorkorder.number}</span>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Button type="button" label="Создать ещё одну заявку" icon="pi pi-plus" onClick={() => initNewWorkorder()}/>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Button type="button" label="К списку заявок" icon="pi pi-chevron-left" className="p-button-secondary" onClick={() => history.push('/workorders')}/>
                    </div>
                </div>
            </Card>
    }

    return (       
        !newWorkorder 
            ? renderNewForm() 
            : renderCreatedForm()
    )
}

export default NewWorkorder;
