import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React, { useEffect, useState } from 'react';
import appHelper from '../../helpers/appHelper';
import profileService from '../../service/ProfileService';


/*
    props.user - to prevent calling useEffect() multiple times
    useEffect() - called after render first to try to get user from sessionStorage (jwt) and fetchProfile
        - if user profile is fetched, then no login process required
        - if no user was fetched (jwt token not exist) - update user state with empty object to render login page
*/

const Login = (props) => {
    
    const [user, setUser] = useState();
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
        
    useEffect(() => {
        appHelper.log('Login useEffect()');

        profileService.fetchProfile().then( res => {
            if(res){
                setUser(profileService.getUser());

                if(res.success){
                    props.history.push(props.location.pathname);
                }
                else{
                    // rerender to Login
                    setUser({});
                }
            }
            else{
                // rerender to Login
                setUser({});
            }
        })
        
    }, [props.user, props.history, props.location.pathname]);

    function handleLogin(event) {
        appHelper.log('handleLogin()');

        profileService.doLogin(login, password).then (res => {
            if(res.success){
                props.history.push(props.location.pathname);
            }
        });
        event.preventDefault();
    }

    return (
            !user ? 'try to fetch first' : (
            <>
            <div className="card card-w-title">
                <h1>Вход в личный кабинет</h1>
                <form onSubmit={(event) => handleLogin(event)}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="pi pi-user"/></span>
                                <InputText name="login" onChange={(e) => setLogin(e.target.value)} placeholder="введите логин" autoFocus/>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="pi pi-key"/></span>
                                <Password name="password" onChange={(e) => setPassword(e.target.value)} placeholder="введите пароль" promptLabel="Введите пароль" feedback={false}/>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <Button type="submit" label="Войти" icon="pi pi-sign-in"/>
                        </div>                  
                    </div>
                </form>
            </div>
            </>)
        );
}

export default Login;