import React, { Component } from 'react';
import { Button } from 'primereact/button';

export class AppFooter extends Component {

    render() {

        let appSite = process.env.REACT_APP_SURFBIRD_URL;
        let appVersion = process.env.REACT_APP_VERSION;

        return  (
            <div className="layout-footer p-d-flex p-flex-column p-ai-center">
                <div className="p-mb-2">
                    <Button label="Desktop-версия" icon="pi pi-desktop" onClick={() => {window.location.href = appSite} } className="p-pl-6 p-pr-6"/>
                </div>
                <div>
                    <span className="footer-text p-pr-2">FMAssistant</span>
                    <img src="assets/layout/images/logo.svg" alt="" width="80"/>
                    <span className="footer-text p-pl-2">v.{appVersion}</span>
                </div>
            </div>
        );
    }
}