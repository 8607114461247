import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import React, { useEffect, useState } from 'react';
import paymentService from './paymentService';

import './payments.scss';
import {Toolbar} from 'primereact/toolbar';
import { Skeleton } from 'primereact/skeleton';
import { useHistory, useLocation } from 'react-router-dom';

const Payments = (props) => {

    const [payments, setPayments] = useState();

    const history = useHistory();

    const stateFilter = new URLSearchParams(useLocation().search);
   
    useEffect( () => {
        paymentService.getPayments(stateFilter)
            .then(data => {
                setPayments(data ? data : {});
            })
    }, []);

    function onGetReport(docId, fileName){
        paymentService.getPaymentReport(docId, fileName);
    }

    function itemTemplate(doc){
        if (!doc) {
            return <div/>;
        }

        var stateClass;
        switch (doc.state.code) {
            case 'UNPAID':
                stateClass =  'unpayed';
                break;
            case 'PAID_COMPLETELY':
                stateClass =  'payed';
                break;
            case 'PAID_PARTIALLY':
                stateClass =  'payed-part';
                break;
            case 'ERROR':
                stateClass =  'error';
                break;                
            default:
                stateClass =  'unpayed';;
        }

        const toolbar = 
            <>
                <Button icon="pi pi-search" className='p-mr-2' label='Открыть' onClick={() => props.history.push('payment-info/' + doc.id)}/>
                <Button icon="pi pi-print" className='p-mr-2' label='PDF' onClick={() => onGetReport(doc.id, doc.text)}/>
            </>
        
        const header = (
            <div className="p-grid payment-item-header">
                <div className="p-col-12 p-lg-10 p-sm-6">
                    <div className="summary">
                    <span className="pi pi-fw pi-book"/><span className="title">{doc.text}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-2 p-sm-6">
                    <div className="summary">
                        <p className={'state ' + stateClass}>{doc.state.description}</p>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="summary">
                        <Toolbar left={toolbar}/>
                    </div>
                </div>
            </div>
        )

        return (
            <Card title={header} className='payment-item'>
                <div><label className="p-text-bold">Номер: </label>{doc.number}</div>
                <div><label className="p-text-bold">Сумма: </label>{doc.amount} руб.</div>
                <div><label className="p-text-bold">Дата: </label>{doc.docDate}</div>
                <div><label className="p-text-bold">Лицевой счет: </label>{doc.personAccountNumber}</div>
                
            </Card>)
    }

    return (   
        <>    
        <div className="p-fluid p-d-flex p-jc-end p-mb-3">
            <Button type="button" label="На рабочий стол" icon="pi pi-chevron-left" className="p-button-secondary" onClick={() => history.push('/dashboard')}/>
        </div>
        {payments 
            ?   <DataView
                    value={payments} 
                    paginator={true}
                    rows={5}
                    layout="list"
                    emptyMessage="Нет данных по платежным документам"
                    itemTemplate={itemTemplate}/>
            :   <Card><Skeleton height="2.5rem"/></Card>        
        }
        </>
    )
}

export default Payments;