import appHelper from '../../../helpers/appHelper';
import httpHelper from '../../../helpers/httpHelper';

const workorderService = {
    getWorkorders,
    getWorkorder,
    getWorkorderReport,
    initNewWorkorder,
    newWorkorder,
    uploadNewFile,
}

export default workorderService;

function getWorkorders(stateFilter) {
    appHelper.log( `getWorkorders() stateFilter: ${stateFilter}`);

    return httpHelper.axiosInst()
        .get('/api/workorder/list', { params: stateFilter })
        .then(res => {
            return res.data;
        });
}

function initNewWorkorder() {
    appHelper.log('initNewWorkorder()');

    return httpHelper.axiosInst()
        .get('/api/workorder/init')
        .then(res => {
            return res.data;
        });
}


function getWorkorder(id){
    appHelper.log(`getWorkorder() id: ${id}`);

    return httpHelper.axiosInst()
            .get(`/api/workorder/${id}`)
            .then(res => {
                return res.data;
            });
} 

function newWorkorder(workorder){
    appHelper.log(`newWorkorder() theme: ${workorder.theme}`);

    return httpHelper.axiosInst()
            .post(`/api/workorder/new`, workorder)
            .then(res => { return httpHelper.requestResult(res); })
            .catch(err => {return httpHelper.requestResult(err.response)});
}  

function uploadNewFile(formData){
    appHelper.log(`uploadNewFile() formData: ${formData}`);

    return httpHelper.axiosInst()
            .post(`/api/file/upload`, formData)
            .then(res => { return httpHelper.requestResult(res); })
            .catch(err => {return httpHelper.requestResult(err.response)});
}  

function getWorkorderReport(workorderId, fileName){
    appHelper.log(`getWorkorderReport() workorderId: ${workorderId}, fileName: ${fileName}`);

    return httpHelper.axiosInst()
            .get(`/api/workorder/report/${workorderId}`, {responseType: 'blob'})
            .then((response) => {
                httpHelper.downloadFile(response.data, fileName + '.pdf')
            });          
}   
