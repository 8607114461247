const appHelper = {
    getAppSitePrefix,
    log
}

export default appHelper;

function getAppSitePrefix(){
    return isDevMode() ? 'Dev@' 
        : isTestMode() ? 'Test@'
        : '';
}

function log(msg){
    if(isDevMode()){
        return console.log(msg);
    }
}

function isDevMode(){
    return process.env.REACT_APP_ENV === 'develop';
}

function isTestMode(){
    return process.env.REACT_APP_ENV === 'test';
}

