
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { AppTopbar } from './AppTopbar';

import Dashboard from './components/fmassistant/Dashboard';
import Login from './components/fmassistant/Login';
import PaymentCallback from './components/fmassistant/payments/PaymentCallback';
import PaymentInfo from './components/fmassistant/payments/PaymentInfo';
import Payments from './components/fmassistant/payments/Payments';
import ProtectedRoute from './components/fmassistant/ProtectedRoute';

import httpHelper from './helpers/httpHelper';
import msgHelper from './helpers/msgHelper';
import profileService from './service/ProfileService';

import './layout/layout.scss';
import './App.scss';
import appHelper from './helpers/appHelper';
import Workorders from './components/fmassistant/workorders/Workorders';
import NewWorkorder from './components/fmassistant/workorders/NewWorkorder';
import WorkorderInfo from './components/fmassistant/workorders/WorkorderInfo';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            
            companyInfo: null,            
            isLoading: false
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        
        this.createMenu();
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [
            {
                label: 'Операции', 
                icon: 'pi pi-fw pi-home',
                items: [
                    {label: 'Рабочий стол', icon: 'pi pi-fw pi-desktop', command: () => {window.location = '#/'}},
                    {label: 'Платежные документы', icon: 'pi pi-fw pi-book', command: () => window.location = '#/payments'},
                    {label: 'Заявки', 
                    icon: 'pi pi-fw pi-briefcase', 
                    items: [
                        {label: 'Список заявок', icon: 'pi pi-fw pi-copy', command: () => window.location = '#/workorders'},
                        {label: 'Создать заявку', icon: 'pi pi-fw pi-file', command: () => window.location = '#/new-workorder'}
                    ]}
                ]
            },
            {
                label: 'Настройки', icon: 'pi pi-fw pi-cog',
                items: 
                    [
                        {
                            label: 'Вид меню', icon: 'pi pi-fw pi-cog',
                            items:  [
                                {label: 'Фиксированное', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'static'}) },
                                {label: 'Перекрывающее', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutMode: 'overlay'}) }
                            ]
                        },
                        {
                            label: 'Цвет меню', icon: 'pi pi-fw pi-align-left',
                            items: [
                                {label: 'Темное', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'dark'}) },
                                {label: 'Светлое', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'light'}) }
                            ]
                        }
                    ]
            }            
        ];
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive){
            this.addClass(document.body, 'body-overflow-hidden');
        }
        else{
            this.removeClass(document.body, 'body-overflow-hidden');
        }
    }

    componentDidMount(){
        msgHelper.init(this.globalToast);
        httpHelper.init(this.isLoadingHandler, this.props.history);
    }

    isLoadingHandler = (isLoading) => {
        this.setState({isLoading: isLoading});
    }

    setCompanyInfo = (companyInfo) => {
        this.setState({companyInfo: companyInfo});
        document.title = appHelper.getAppSitePrefix() + 'FMAssistant (Sigma) - ' + companyInfo.pageTitle;
    }

    render() {
        
        //const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';
        
        const logo = this.state.companyInfo 
                    ? `assets/layout/images/clients/logo/${this.state.companyInfo.resourceFolder}/logo.png`
                    : '';
        
        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        let isAuth = profileService.isAuthenticated();

        return (
        <>
            {msgHelper.clear()}

            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar 
                    isAuth={isAuth} 
                    user={profileService.getUser()}
                    onToggleMenu={this.onToggleMenu} 
                    setLayoutMode={ layout => { this.setState({layoutMode: layout}) }}
                    setCompanyInfo={ this.setCompanyInfo }
                    isLoading={this.state.isLoading} />

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                    
                    {this.state.companyInfo &&
                        <div className="layout-logo">
                            <img alt="Logo" src={logo} />
                        </div>
                    }
                    {isAuth && <AppProfile />}
                    {isAuth && <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />}
                </div>
                
                <div className="layout-main">
                    <Toast ref={(el) => { this.globalToast = el}}/>

                    {!isAuth && <Route path="/*" render={(props) => <Login {...props} user={profileService.getUser()} />}/>}
                    {isAuth && <Switch>
                        
                        <Route exact path="/">
                            <Redirect to="/dashboard"/> 
                        </Route>
                        
                        {/* FMAssistant routes */}
                        <ProtectedRoute path="/dashboard" exact component={Dashboard} />
                        
                        <ProtectedRoute path="/payments" component={Payments}/>
                        <ProtectedRoute path="/payment-info/:docId" component={PaymentInfo} />
                        <ProtectedRoute path="/payment-success/:docId" component={PaymentCallback}/>

                        <ProtectedRoute path="/workorders" component={Workorders}/>
                        <ProtectedRoute path="/workorder-info/:docId" component={WorkorderInfo} />
                        <ProtectedRoute path="/new-workorder" component={NewWorkorder}/>
                    </Switch>}
                </div>

                <AppFooter />
            </div>
        </>
        );
    }
}

export default withRouter(App);
