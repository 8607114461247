import appHelper from './appHelper';

const envHelper = {
    getVar    
}

export default envHelper;

const dev = {
    FMABOT_SUFFIX: "_Dev_Bot"
}

const test = {
    FMABOT_SUFFIX: "_Test_Bot"
}

const prod = {
    FMABOT_SUFFIX: "Bot"
}


class EnvHelper{
    env = process.env.REACT_APP_ENV === "develop" ? dev 
    : process.env.REACT_APP_ENV === "test" ? test
    : process.env.REACT_APP_ENV === "production" ? prod
    : {};

    getVar(varName){
        appHelper.log('getVar(): varName: ' +  varName);
        appHelper.log('env: ' +  JSON.stringify(this.env));
        appHelper.log('process.env: ' +   JSON.stringify(process.env));

        appHelper.log(varName + ': ' +  this.env[varName]);

        return this.env[varName];
    }
}

const env = new EnvHelper();

function getVar(varName){
    return env.getVar(varName);
}



