import React from 'react';

const msgHelper = {
    init,
    show,
    showSuccess,
    clear
}

export default msgHelper;

function init(appToast){
    helper.init(appToast);
}

function show(isRaw, severity, summary, detail){
    helper.show(isRaw, severity, summary, detail);
}

function showSuccess(summary, detail){
    helper.show(false, 'success', summary, detail);
}

function clear(){
    helper.clear();
}

class MsgHelper{
    init(appToast){
        this.toast = appToast;
    }

    show = (isRaw, severity, summary, detail) => {
    
        let stick = false;

        if(severity === 'error'){
            stick = true;
        }

        if(isRaw){
            let msgHtml = {__html: summary};
            
            this.toast.show({severity: severity, sticky: stick, content: (
                <div dangerouslySetInnerHTML={msgHtml} style={{overflow: 'auto', fontSize: '0.9rem', height: '40rem'}}/>
            )});
        }
        else{
            this.toast.show({severity: severity, sticky: stick, summary: summary, detail: detail});
        }
    }
    
    clear = () => {
        this.toast && this.toast.clear();
    }
}

const helper = new MsgHelper();