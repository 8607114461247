import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { InputTextarea } from 'primereact/inputtextarea';

import './workorders.scss';

import workorderService from './workorderService';
import FileView from '../files/FileView';

const WorkorderInfo = (props) => {
    const { docId } = useParams();
    const [doc, setDoc] = useState();
    const [messages, setMessages] = useState();
    
    const history = useHistory();

    const filesRef = useRef(null);
    const scrollToFiles = () => {filesRef.current.scrollIntoView()};

    const commnetsRef = useRef(null)
    const scrollToComments = () => {commnetsRef.current.scrollIntoView()};

    useEffect( () => {
        if(messages){
            if(!doc){
                workorderService
                    .getWorkorder(docId)
                    .then(doc => {
                        setDoc(doc);

                        if(doc.messages){
                            doc.messages.forEach(msg => {
                                messages.show({...msg, sticky: false});
                            }); 
                        }
                     });
            }
        }
    }, [docId, doc, messages]);

    function onGetReport(){
        workorderService.getWorkorderReport(doc.id, 'Заявка_' + doc.number);
    }

    

    function renderHeaderToolbar(){
        return <span className="p-buttonset">
            <Button icon="pi pi-print" className="p-mr-2 p-mb-2" label='PDF' onClick={() => onGetReport()} disabled={!doc}/>
        </span>
    }

    function renderDocHeader(){
        if(!doc){
            return <Skeleton height="3.5rem" />;
        }

        var stateClass = 'working';
        switch (doc.state.code) {
            case 'CRE':
                stateClass =  'new';
                break;
            case 'STD':
            case 'RTE':
            case 'EDC':
            case 'INW':
                stateClass =  'working';
                break;
            case 'WIW':
                stateClass =  'waiting';
                break;
            case 'CLS':
                stateClass =  'closed';
                break;                
            default:
                stateClass =  'working';
        }
    
        return (
            <div className="p-grid workorder-item-header">
                <div className="p-col-12 p-lg-9 p-sm-6 p-d-flex p-ai-center">
                    <div className="summary">
                        <span className="pi pi-fw pi-file"/><span className="title">{doc.theme}</span>

                        <span className="wo-notification">
                            {doc.hasComments && <Button icon="pi pi-bell" className={'p-button-rounded ' + (doc.hasNewComments ? 'p-button-warning' : 'p-button-outlined') + ' p-ml-4'} onClick={scrollToComments}/>}
                            {doc.hasAttachment && <Button icon="pi pi-paperclip" className={'p-button-rounded p-button-outlined ' + (doc.hasNewAttachment ? 'p-button-warning' : 'p-button-outlined') + ' p-ml-4'} onClick={scrollToFiles}/>}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-3 p-sm-6">
                    <div className="summary">
                        <p className={'state ' + stateClass}>{doc.state.description}</p>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="summary">
                        <Toolbar left={renderHeaderToolbar()}/>
                    </div>
                </div>
            </div>
        )       
    }

    function renderDoc(){
        return <Card title={renderDocHeader()}>
                <Fieldset legend='Заявка'>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-grid">
                            <label htmlFor="number" className="p-col-12 p-md-2">Номер</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputText id="number" value={doc.number} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-grid">
                            <label htmlFor="theme" className="p-col-12 p-md-2">Тема</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputText id="theme" value={doc.theme} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-grid">
                            <label htmlFor="dateCreate" className="p-col-12 p-md-4">Дата создания</label>
                            <div className="p-col-12 p-md-8">
                                {doc 
                                    ?   <div className="p-inputgroup">
                                            <InputText id="dateCreate" value={doc.dateCreate} readOnly />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-calendar"></i>
                                            </span>
                                        </div>
                                    : <Skeleton height="2.5rem" />
                                }
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-grid">
                            <label htmlFor="dateClose" className="p-col-12 p-md-4">Дата закрытия</label>
                            <div className="p-col-12 p-md-8">
                                {doc 
                                    ?   <div className="p-inputgroup">
                                            <InputText id="dateClose" value={doc.dateClose ? doc.dateClose : ''} readOnly />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-calendar"></i>
                                            </span>
                                        </div>
                                    : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-grid">
                            <label htmlFor="category" className="p-col-12 p-md-2">Категория</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputText id="category" value={doc.category ? doc.category : '<не указана>'} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-grid">
                            <label htmlFor="text" className="p-col-12 p-md-2">Содержимое заявки</label>
                            <div className="p-col-12 p-md-10">
                                {doc ? <InputTextarea id="text" rows="4" value={doc.text ? doc.text : ''} readOnly /> : <Skeleton height="2.5rem" />}
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <br/>
                <div className="p-fluid" ref={filesRef}>
                    <Fieldset legend='Вложения'>
                        {doc && doc.files.map( file => 
                            <FileView file={file} key={file.id}/>
                        )}                        
                    </Fieldset>
                </div>
                <br/>
                <div className="p-fluid" ref={commnetsRef}>
                    <Fieldset legend="Комментарии">
                        {doc && doc.comments.map( com => 
                            <div className="p-card p-grid p-mb-3" key={com.id}>
                                <div className="p-col-12 p-text-bold"><span>{com.author}</span> <span>{com.dateCreate}</span></div>
                                <div className="p-col-12">{com.txt}</div>
                            </div>
                        )}                        
                    </Fieldset>
                </div>                
            </Card>
    }

    return (    
        <>
            <Toast ref={ el => setMessages(el) }/>
            <div className="p-fluid p-d-flex p-jc-end p-mb-3">
                <Button type="button" label="К списку заявок" icon="pi pi-chevron-left" className="p-button-secondary" onClick={() => history.push('/workorders')}/>
            </div>
            {renderDoc()}
        </>)
}

export default WorkorderInfo;