import appHelper from '../../../helpers/appHelper';
import httpHelper from '../../../helpers/httpHelper';

const paymentService = {
    getPayments,
    getPayment,
    pay,
    payCallbcak,
    getPaymentReport
}

export default paymentService;

function getPayments(stateFilter) {
    appHelper.log(`getPayments() stateFilter: ${stateFilter}`);

    return httpHelper.axiosInst()
        .get('/api/payment/list', { params: stateFilter })
        .then(res => {
            return res.data;
        });
}

function getPayment(id){
    appHelper.log(`getPayment() id: ${id}`);

    return httpHelper.axiosInst()
            .get(`/api/payment/${id}`)
            .then(res => {
                return res.data;
            });
}    

function pay(docId, params){
    appHelper.log(`pay() id: ${docId}`);

    return httpHelper.axiosInst()
            .post(`/api/payment/pay/${docId}`, null, params)
            .then((response) => {
                return response.data;
              });           
}

function payCallbcak(docId){
    appHelper.log(`payCallbcak() id: ${docId}`);

    return httpHelper.axiosInst()
            .post(`/api/payment/callback/${docId}`)
            .then((response) => {
                return response.data;
              });           
}

function getPaymentReport(docId, fileName){
    appHelper.log(`getPaymentReport() docId: ${docId}, fileName: ${fileName}`);

    return httpHelper.axiosInst()
            .get(`/api/payment/report/${docId}`, {responseType: 'blob'})
            .then((response) => {
                httpHelper.downloadFile(response.data, fileName + '.pdf')
            });          
}   
