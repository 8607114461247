import React from "react";
import { Route } from "react-router-dom";
import profileService from "../../service/ProfileService";
import Login from "./Login";


const ProtectedRoute = ({component: Component, ...rest}) => {

    let isAuth = profileService.isAuthenticated();
    //console.log(`ProtectedRoute: component ${Component.name} isAuth = ${isAuth}`);

    if(isAuth){
        return <Route {...rest} render={(props) => <Component {...props} {...rest}/>}/>;
    }
    else{
        return <Route {...rest} component={Login}/>;
    }
}

export default ProtectedRoute;