import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';

import paymentService from './paymentService';

import './payments.scss';

const PaymentCallback = (props) => {
    const { docId }= useParams();
    const [doc, setDoc] = useState();
    const [messages, setMessages] = useState();
    
    useEffect( () => {
        if(messages){
            if(!doc){
                paymentService.payCallbcak(docId).then(doc => {
                    setDoc(doc);

                    let hasErrors = false;
                    doc.messages.forEach(msg => {
                        messages.show({...msg, sticky: true});
                        if(msg.severity === "error"){
                            hasErrors = true;
                        }
                    }); 
                    if(!hasErrors){
                        messages.show({severity: 'success', summary: 'Документ успешно оплачен', sticky: true});                    
                    }
                    
                });
            }
        }
    }, [docId, doc, messages]);

    function renderDocHeader(){
        var stateClass;
        switch (doc && doc.state.code) {
            case 'UNPAID':
                stateClass =  'unpayed';
                break;
            case 'PAID_COMPLETELY':
                stateClass =  'payed';
                break;
            case 'PAID_PARTIALLY':
                stateClass =  'payed-part';
                break;
            case 'ERROR':
                stateClass =  'error';
                break;                
            default:
                stateClass =  'unpayed';;
        }
    
        return (
            <div className="p-grid p-fluid payment-item-header">
                <div className="p-col-12 p-lg-10">
                    <div className="summary">
                        <span className="title">{doc && doc.text}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-2 p-text-nowrap">
                    <div className="summary">
                        <span className={'state ' + stateClass}>{doc && doc.state.description}</span>
                    </div>
                </div>
            </div>
        )
    }

    function renderDoc(){
        return <Card title={renderDocHeader()}>
                <div className="p-fluid">
                    <div className="p-field p-grid">
                        <label htmlFor="personAccountNumber" className="p-col-12 p-md-2">Лицевой счет</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="personAccountNumber" value={doc && doc.personAccountNumber} readOnly />
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="docNumber" className="p-col-12 p-md-2">Номер</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="docNumber" value={doc && doc.number} readOnly />
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="docDate" className="p-col-12 p-md-2">Дата</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="docDate" value={doc && doc.docDate} readOnly/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="docSum" className="p-col-12 p-md-2">Сумма документа</label>
                        <div className="p-col-12 p-md-10">
                            <InputNumber id="docSum" value={doc && doc.amount} suffix=" руб." readOnly/>
                        </div>
                    </div>
                </div>                
            </Card>
    }

    return (    
        <>
            <Messages ref={ el => setMessages(el) }/>
            {renderDoc()}
        </>)
}

export default PaymentCallback