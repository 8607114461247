import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appHelper from './helpers/appHelper';

import profileService from './service/ProfileService';

export const AppTopbar = (props) => {
    
    let [companyName, setCompanyName] = useState('');
    
    const history = useHistory();
    
    useEffect( () => {
        appHelper.log('AppTopbar useEffect()');

        let user = props.user;
        if(user){
            setCompanyName(user.managedObject ? user.managedObject : user.company);
        }
        else{
            if(companyName === ''){
                profileService.fetchCompany()
                    .then(res => {
                        if(res.success){
                            props.setCompanyInfo(res.data);
                            setCompanyName(res.data.pageTitle);
                        }
                    })
                }
        }
    }, [props.user, companyName]);

    function handleLogout() {
        profileService
            .logout()
            .then( () =>{
                history.push('/');
            });        
    }

    return (
            <div className="layout-topbar clearfix">
                <div className="p-grid">
                    <div className="p-col-fixed">
                        <Button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                            <span className="pi pi-list"/>
                        </Button>
                    </div>
                    <div className="p-col p-grid">
                        <div className="p-col-9">                        
                            <h3 className="app-company-name p-text-wrap">{companyName}</h3>
                        </div>
                        <div className="p-col-3">                        
                            <div className="layout-topbar-icons">
                                <div className="p-grid">
                                    
                                    {props.isAuth &&
                                    <>
                                    <div className="p-col-6">
                                        <button className="p-link" onClick={handleLogout}>
                                            <span className="layout-topbar-icon pi pi-power-off"/>
                                        </button>
                                    </div> 
                                    </>
                                    }
                                    <div className="p-col-6">
                                        {props.isLoading 
                                            ? <ProgressSpinner style={{width: '2.2rem', height: '2.2rem'}} strokeWidth="8" fill="#EEEEEE"/>
                                            : <div style={{paddingLeft: '2.2rem'}}></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>       
                </div>
            </div>
    );
}