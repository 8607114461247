import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import {Toolbar} from 'primereact/toolbar';
import { Skeleton } from 'primereact/skeleton';

import workorderService from './workorderService';
import './workorders.scss';

const Workorders = (props) => {

    const [workorders, setWorkorders] = useState();
    
    const history = useHistory();

    const stateFilter = new URLSearchParams(useLocation().search);

    useEffect( () => {
        workorderService.getWorkorders(stateFilter)
            .then(data => {
                setWorkorders(data ? data : {});
            })
    }, []);

    function onGetReport(workorderId, fileName){
        workorderService.getWorkorderReport(workorderId, fileName);
    }

    function itemTemplate(workorder){
        if (!workorder) {
            return <div/>;
        }

        var stateClass = 'working';
        switch (workorder.state.code) {
            case 'CRE':
                stateClass =  'new';
                break;
            case 'STD':
            case 'RTE':
            case 'EDC':
            case 'INW':
                stateClass =  'working';
                break;
            case 'WIW':
                stateClass =  'waiting';
                break;
            case 'CLS':
                stateClass =  'closed';
                break;                
            default:
                stateClass =  'working';
        }

        const toolbar = 
            <>
                <Button icon="pi pi-search" className='p-mr-2' label='Открыть' onClick={() => props.history.push('workorder-info/' + workorder.id)}/>
                <Button icon="pi pi-print" className='p-mr-2' label='PDF' onClick={() => onGetReport(workorder.id, 'Заявка_' + workorder.number)}/>
            </>
        
        const header = (
            <div className="p-grid workorder-item-header">
                <div className="p-col-12 p-lg-9 p-sm-6 p-d-flex p-ai-center">
                    <div className="summary">
                        <span className="pi pi-fw pi-file"/><span className="title">{workorder.theme}</span>
                        
                        <span className="wo-notification">
                            {workorder.hasComments && <Button icon="pi pi-bell" className={'p-button-rounded ' + (workorder.hasNewComments ? 'p-button-warning' : 'p-button-outlined') + ' p-ml-4'}/>}
                            {workorder.hasAttachment && <Button icon="pi pi-paperclip" className={'p-button-rounded p-button-outlined ' + (workorder.hasNewAttachment ? 'p-button-warning' : 'p-button-outlined') + ' p-ml-4'} />}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-3 p-sm-6">
                    <div className="summary">
                        <p className={'state ' + stateClass}>{workorder.state.description}</p>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="summary">
                        <Toolbar left={toolbar}/>
                    </div>
                </div>
            </div>
        )

        return (
            <Card title={header} className='workorder-item'>
                <div><label className="p-text-bold">Номер: </label>{workorder.number}</div>
                <div><label className="p-text-bold">Дата: </label>{workorder.dateCreate}</div>
                <div><label className="p-text-bold">Категория: </label>{workorder.category ? workorder.category : '<не указана>'}</div>
                <div className="p-d-flex wo-text-container">
                    <div className="p-mr-2"><label className="p-text-bold">Содержимое заявки: </label></div>
                    <div className="wo-text">
                        <span  className="p-text-truncate">{workorder.text}</span>
                    </div>
                </div>
            </Card>)
    }

    return ( 
        <>    
        <div className="p-fluid p-d-flex p-jc-end p-mb-3">
            <Button type="button" label="На рабочий стол" icon="pi pi-chevron-left" className="p-button-secondary" onClick={() => history.push('/dashboard')}/>
        </div>
        {workorders 
            ?   <DataView
                value={workorders} 
                paginator={true}
                rows={5}
                layout="list"
                emptyMessage="Нет данных по заявкам"
                itemTemplate={itemTemplate}/>
            :   <Card><Skeleton height="2.5rem"/></Card>        
        }
        </>
    )


}

export default Workorders;
