import appHelper from "../helpers/appHelper";
import httpHelper from "../helpers/httpHelper";
import jwtHelper from '../helpers/jwtHelper';

 class ProfileService {
    constructor(){
        this.user = null;
        this.companyInfo = null;
    }

    getCompanyInfo(){
        return this.companyInfo;
    }

    isAuthenticated() {
        return this.user != null;
    }

    getUser(){
        return this.user;
    }

    async fetchProfile() {
        appHelper.log('fetchProfile()');
        
        if(jwtHelper.getJWT()){
            try {
                const res = await httpHelper.axiosInst().get('/api/profile/user');
                this.user = res.data;
                
                return httpHelper.requestResult(res);
            }
            catch (err) {
                jwtHelper.clearJWT();
                return httpHelper.requestResult(err.response);
            }
        }
        return Promise.resolve();
    }

    async fetchCompany() {
        appHelper.log('fetchCompany()');
        
        try {
            const res = await httpHelper.axiosInst().get('/api/profile/company');
            this.companyInfo = res.data;

            return httpHelper.requestResult(res);
        }
        catch (err) {
            return httpHelper.requestResult(err.response);
        }
    }


    async doLogin(login, password) {
        appHelper.log(`doLogin() login: ${login}, password: ${password}`);
       
        try {
            const res = await httpHelper.axiosInst()
                .post('/api/login', {
                    params: {
                        'login': login,
                        'password': password
                    }
                });
            jwtHelper.setJWT(res.headers['authorization']);
            this.user = res.data;
            
            return httpHelper.requestResult(res);
        } 
        catch (err) {
            jwtHelper.clearJWT();
            return httpHelper.requestResult(err.response);
        }
    }

    async logout(){
        appHelper.log(`logout() user: ${this.user ? this.user.person : '<no user>'}`);
        
        try {
            const res = await httpHelper.axiosInst().post('/api/profile/logout');
            jwtHelper.clearJWT();
            this.user = null;
            
            return httpHelper.requestResult(res);
        } 
        catch (err) {
            jwtHelper.clearJWT();
            this.user = null;

            httpHelper.requestResult(err.response);
        }
    }

    async getClientStats(){
        appHelper.log(`getClientStats()`);
    
        try{
            const res = await httpHelper.axiosInst().get(`/api/profile/clientStats`)
            return httpHelper.requestResult(res);
        }
        catch (err) {
            httpHelper.requestResult(err.response);
        }
    }   
}

export default new ProfileService();