import { Button } from 'primereact/button';
import React from 'react';

import httpHelper from '../../../helpers/httpHelper';

const FileView = (props) => {
    const file = props.file;

    const downloadFile = () => {
        httpHelper.axiosInst()
            .get(`/api/file/${file.id}`, {responseType: 'blob'})
            .then((res) => {
                httpHelper.downloadFile(res.data, file.name);
            });
    }

    return (
        <div className="p-card p-grid p-d-flex p-ai-center" key={file.id}>
            
            <div className="p-col-12 p-md-1" onClick={downloadFile}>
            <Button className="p-button-text p-p-0">
                {file.type === 'IMAGE' && <img src={`data:image/jpeg;base64,${file.thumbnail}`} alt={file.name}/>}
                {file.type === 'PDF' && <img src="assets/layout/images/file-types/pdf.png" alt="PDF"/>}
                {file.type === 'EXCEL' && <img src="assets/layout/images/file-types/excel.png" alt="EXCEL"/>}
                {file.type === 'WORD' && <img src="assets/layout/images/file-types/word.png" alt="WORD"/>}
                {file.type === 'TXT' && <img src="assets/layout/images/file-types/txt.png" alt="TXT"/>}
                {file.type === 'UNKNOWN' && <img src="assets/layout/images/file-types/unknown.png" alt="UNKNOWN"/>}
            </Button>
            </div>
            
            <div className="p-col-12 p-md-10">{file.name}</div>
            <div className="p-col-12 p-md-1">{file.humanSize}</div>
        </div>

    )
}

export default FileView;